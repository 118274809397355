<template>
    <div class="box">
        <title-tap
            v-if="!showFollowPage"
            :active-name="activeName"
            :tab-data="tabData"
            @selectInfo="selectInfo"
        />

        <!-- 头部列表选项卡 -->
        <!-- <div class="list">
      <span
        v-for="(item, index) in list"
        :key="index"
        @click="ck(index)"
        :class="{ active: index == isShow }"
        >{{ item }}</span
      >
        </div>-->
        <!-- 线索信息和客户信息 -->
        <follow v-if="showFollowPage" ref="follow" @closeFollow="closeFollow" />
        <!-- 选择的页面 -->
        <synch-newclue
            v-if="isShow == 0 && !showFollowPage"
            ref="synchNewclue"
            @followPage="followPage"
        />
        <!-- 新线索 -->
        <new-clue
            v-if="isShow == 1 && !showFollowPage"
            ref="newClue"
            style="height: calc(100% - 54px)"
            @followPage="followPage"
        />
        <!-- 未确认线索 -->
        <unconfirmed
            v-if="isShow == 2 && !showFollowPage"
            ref="unconfirmed"
            style="height: calc(100% - 54px)"
            @followPage="followPage"
        />
        <!-- 抓取线索 -->
        <crawl
            v-if="isShow == 3 && !showFollowPage"
            ref="crawl"
            style="height: calc(100% - 54px)"
            @followPage="followPage"
        />
        <!-- 转给我的 -->
        <trunme
            v-if="isShow == 4 && !showFollowPage"
            ref="trunme"
            style="height: calc(100% - 54px)"
            @followPage="followPage"
        />
    </div>
</template>

<script>
import synchNewclue from './mylist/synchNewclue';
import newClue from './mylist/newclue';
import unconfirmed from './mylist/unconfirmed';
import crawl from './mylist/crawl';
import trunme from './mylist/trunme';
import follow from './follow.vue';
import titleTap from '@/components/templates/title-tap';
import { elements } from '@/api/public_content';
export default {
    components: {
        unconfirmed,
        crawl,
        trunme,
        follow,
        titleTap,
        synchNewclue,
        newClue,
    },
    inject: ['refreshClueData'],
    data() {
        return {
            // list: ["新线索", "未确认", "我抓取的", "转给我的", "线索记录"],
            isShow: 0,
            show: false,
            paramId: '',
            shuaNum1: '',
            tabData: [
                '实时新线索',
                '新线索',
                '未确认的',
                '我抓取的',
                '转给我的',
            ],
            activeName: '0',
            showFollowPage: false,
        };
    },
    created() {
        // 这是为了解决切换后或者
        //刷新后还是在这个标签
        let c = sessionStorage.getItem('path1');
        console.log(c);
        if (c) {
            this.isShow = c;
            this.activeName = c.toString();
        }
        this.getHasRole();
    },
    mounted() {
        if (this.$store.state.followData !== null) {
            this.followPage(this.$store.state.followData);
            this.$store.commit('changeFollowData', null);
        }
        this.$EventBus.$on('showDialog', this.showDialogHandle);
    },
    destroyed() {
        this.$EventBus.$off('showDialog', this.showDialogHandle);
    },
    methods: {
        getHasRole() {
            elements({ pageCode: 'my-clue' }).then((res) => {
                console.log(res);
                for (let i = 0; i < res.data.list.length; i++) {
                    if (res.data.list[i].code === 'btn-clue-new-transfer') {
                        // this.showTransBtn = true;
                        sessionStorage.setItem('showTransBtn', true);
                    }
                }
            });
        },
        idc(val) {
            console.log(val);
            this.paramId = val;
            this.show = true;
        },
        xianshi1(val) {
            this.show = val;
        },
        shuaxin(val) {
            console.log(val);
            this.shuaNum1 = val;
            // if (val) this.$refs.newclue.shuaxin();
        },
        // ck(e) {
        //   this.isShow = e;
        //   console.log(this.isShow);
        //   if (e === 0) {
        //     this.$nextTick(() => {
        //       this.$refs.newclue.shuaxin(e);
        //     });
        //   }
        //   this.show = false;
        //   //存储这一次点击保存的标签
        //   sessionStorage.setItem("path1", e);
        // },
        selectInfo(val, isHande) {
            this.showFollowPage = false;
            let index = val.index || '';
            if (isHande) {
                this.isShow = isHande;
                this.activeName = '0';
            } else {
                console.log(index);
                this.isShow = index;
                this.activeName = index;
                if (index === 0) {
                    this.$nextTick(() => {
                        this.$refs.synchNewclue.shuaxin(index);
                    });
                    // this.show = false;
                    sessionStorage.setItem('path1', index);
                }
            }
            this.isShow = index;
            if (index === 0) {
                this.$nextTick(() => {
                    this.$refs.synchNewclue.shuaxin(index);
                });
            }
            //存储这一次点击保存的标签
            sessionStorage.setItem('path1', index);
        },
        followPage(row) {
            this.showFollowPage = true;
            this.$nextTick(() => {
                this.$refs.follow.init(row);
            });
        },
        closeFollow() {
            this.$nextTick(() => {
                this.showFollowPage = false;
            });
            console.log(this.$refs.follow);
            this.refreshClueData();
        },
        //  弹窗事件处理
        showDialogHandle(val) {
            this.followPage(val);
        },
    },
};
</script>
<style lang="less" scoped>
::v-deep .el-switch__core {
    width: 30px !important;
}
::v-deep .el-switch__core:after {
    width: 9px;
    height: 9px;
}
::v-deep .el-switch__core {
    height: 14px;
}
::v-deep .el-switch.is-checked .el-switch__core::after {
    left: 100%;
    margin-left: -10px;
}
.box {
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    .list span {
        display: inline-block;
        width: 120px;
        height: 40px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(14, 5, 9, 0.2);
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }
    .list span.active {
        background: #4086ec;
        color: #fff;
    }

    .circulation {
        ::v-deep .el-timeline-item {
            overflow: hidden;
        }
        ::v-deep .el-timeline-item__timestamp {
            margin-top: 4px;
            color: #b2b2b2;
            float: left;
        }
        ::v-deep .el-timeline-item {
            padding-bottom: 5px;
        }
        .fl {
            float: left;
            color: #b2b2b2;
            font-size: 13px;
        }
    }
    .tk > ::v-deep .el-dialog {
        width: 75%;
        height: 75vh;
        overflow: auto;
    }
    .tk {
        ::v-deep .el-dialog__header {
            text-align: center;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
        }
        ::v-deep .el-dialog__body {
            padding: 0 20px;
        }
    }
}
::v-deep .el-timeline-item__node--normal {
    left: 0;
}
</style>
