<template>
    <div
        v-show="!show"
        ref="page"
        class="row1"
        style="height: calc(100vh - 110px); overflow: hidden"
    >
        <!-- 新线索板块 -->
        <main>
            <p class="title">
                线索列表
                <el-button class="batchCallBtn" size="small" type="primary" @click="batchCall">
                    <!-- :disabled="list1.length === 0" -->
                    批量呼叫
                </el-button>
            </p>
            <!-- 无线索的时候显示的页面 -->
            <div v-if="list1.length === 0" class="none">
                <div class="k">
                    <img src="../../../../public/images/noneclue.png" alt />
                    <p>暂无线索，可以去客户列表跟进一下哦</p>
                </div>
            </div>
            <!-- 有线索的时候显示页面 -->
            <el-row
                v-else
                v-infinite-scroll="loadMore"
                infinite-scroll-disabled="disabled"
                :gutter="20"
                style="overflow: scroll; height: 95%; padding-top: 20px"
            >
                <el-col v-for="item in list1" :key="item.id" :span="6">
                    <div
                        :class="[item.id == activeIndex ? 'item active' : 'item remove-active']"
                        @mouseover="changeActive(item)"
                        @mouseout="removeActive(item)"
                        @click="followup(item)"
                    >
                        <div class="top">
                            <div class="one">
                                <div class="mobile-area">
                                    <img
                                        src="@/assets/searchPage/phone.png"
                                        alt
                                        width="20px"
                                        height="20px"
                                        style
                                    />
                                    <span>{{ common.hidePhoneNumber(item.mobileNumber) }}</span>
                                </div>

                                <i>{{ item.fundNeeds }}万</i>
                            </div>

                            <p class="two">
                                <span>姓名：{{ item.name }}</span>
                                <span style="margin-right: 10px">性别：{{ item.sex }}</span>
                                <span>年龄：{{ item.age }}</span>
                            </p>
                            <p class="three">
                                <el-row :gutter="10">
                                    <el-col v-if="item.hasVehicle == 1" :span="25">
                                        <span class="car">车</span>
                                    </el-col>
                                    <el-col v-if="item.hasHouse == 1" :span="25">
                                        <span class="house">房</span>
                                    </el-col>
                                    <el-col v-if="item.hasGongjijin == 1" :span="25">
                                        <span class="gongjijin">金</span>
                                    </el-col>
                                    <el-col v-if="item.hasShebao == 1" :span="25">
                                        <span class="shebao">社</span>
                                    </el-col>
                                    <el-col v-if="item.hasBaodan == 1" :span="25">
                                        <span class="baodan">保</span>
                                    </el-col>
                                </el-row>
                            </p>
                        </div>
                        <div class="bottom">
                            <!-- leftOld没写样式 -->
                            <div class="felx-sb">
                                <div>
                                    <p v-if="item.newClient == 0" class="left leftOld">老客户</p>
                                    <p v-if="item.newClient == 1" class="left leftNew">新客户</p>
                                </div>
                                <i v-if="item.important == 1" class="iconfont icon-aixin" />
                            </div>

                            <p>
                                <time style="color: #d9001b">{{ item.restTime }}</time>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    style="margin-left: 5px"
                                    @click="followup(item)"
                                >跟进</el-button>
                            </p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="24">
                    <p v-if="loadMoreData" class="clue" style="text-align: center; margin: 10px 0">
                        <span>加载中</span>
                    </p>
                </el-col>
                <el-col :span="24">
                    <p
                        v-if="noMoreData"
                        class="clue"
                        style="text-align: center; margin: 10px 0"
                    >- 没有更多了-</p>
                </el-col>
            </el-row>
        </main>
    </div>
</template>

<script>
import { setInterval, clearInterval } from 'timers';
import { synchNewClue } from '@/api/my_clue.js';
import commonAlert from '@/mixins/commonAlert.js';
import clueBulkCall from '@/mixins/clueBulkCall.js';
export default {
    // inject: ["reload"],
    // props: ["statusClue", "shuaN"],
    inject: ['refreshClueData'],
    mixins: [clueBulkCall, commonAlert],
    data() {
        return {
            pageType: 2, //他是一个静态属性，用于帮助混淆识别页面，如果你不知道他的真实作用，请不要更改他
            istime: 0,
            showTurnCallDialog: false,
            sjlist1: [],
            lft: 0,
            hid: 'hidden',
            weizhi: '',
            lenK: 0,
            paramValue: 'Qyp',
            paramId: 'Qyp',
            data: [],
            show: false,
            activeIndex: '',
            loadMoreData: false, //加载更多
            // noMoreData: false, //没有更多了
            pageWidth: '', //页面宽度
            status: {
                pageNo: 1,
                pageSize: 15,
            },
            list1: [],
            total: '',
            timer: null,
            // loading: true,
        };
    },
    created() {},
    mounted() {
        if (this.timer) {
            //这一段是防止进入页面出去后再进来计时器重复启动
            clearInterval(this.timer);
        }
        this.getNewClue();
        // window.addEventListener("setItem", () => {
        //   // 判断是否有新数据来
        //   if (sessionStorage.getItem("newClueGet")) {
        //     this.getNewClue();
        //   }
        // });
    },
    components: {
        // follow,
    },
    watch: {
        clueItem(val) {
            alert(val);
        },
        hasNewClue(newVal, oldVal) {
            clearInterval(this.timer);
            console.log(this.timer);
            this.getNewClue();
        },
    },
    computed: {
        noMoreData() {
            return this.status.pageSize >= this.total;
        },
        disabled() {
            return this.loadMoreData || this.noMoreData;
        },
        hasNewClue() {
            return this.$store.state.newClue;
        },
    },
    methods: {
        // 批量呼叫
        batchCall() {
            if (this.callServices.isDisabled()) {
                this.alertCallServicesNotOpen();
                return;
            }

            this.jumpTurnCallPage();
        },
        // 获得新线索
        getNewClue() {
            let data = {
                pageNo: this.status.pageNo,
                pageSize: this.status.pageSize,
            };
            synchNewClue(data).then((res) => {
                if (res.code == 0) {
                    // this.loading = true;
                    let data = res.data.list;
                    data.forEach((item) => {
                        item.restTime = this.common.InitTime(item.expiredTime);
                        let clientSex = this.common.allValueNeed('sex');
                        clientSex.forEach((i) => {
                            if (item.sex == i.number) {
                                item.sex = i.cnVal;
                            }
                        });
                    });
                    this.list1 = res.data.list;
                    this.list1.forEach((item) => {
                        let timer = null;
                        clearInterval(timer);
                        timer = setInterval(() => {
                            item.restTime = this.common.InitTime(
                                item.expiredTime
                            );
                            let outTime =
                                parseInt(item.expiredTime) -
                                new Date().getTime() / 1000;
                            if (parseInt(outTime) < 0) {
                                clearInterval(timer);
                                let index = data.findIndex((ele) => {
                                    return ele.id === item.id;
                                });
                                if (index >= 0) {
                                    data.splice(index, 1);
                                }
                                // this.$notify({
                                //   title: "线索超时",
                                //   dangerouslyUseHTMLString: true,
                                //   message:
                                //     "线索（" +
                                //     item.name +
                                //     "）超时已流入<font style='color:#4086EC'>【公共池】</font>",
                                // });
                                this.refreshClueData();
                            }
                            // console.log(outTime);
                        }, 1000);
                        this.$once('hook:beforeDestroy', () => {
                            clearInterval(timer);
                        });
                    });
                    // this.beginTime();
                    this.total = res.data.total;
                    this.loadMoreData = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        beginTime() {
            this.ticker = setInterval(() => {
                for (let i = 0, len = this.list1.length; i < len; i++) {
                    // const item = this.list1[i];
                    this.list1[i].restTime = this.common.InitTime(
                        this.list1[i].expiredTime
                    );
                    let outTime =
                        parseInt(this.list1[i].expiredTime) -
                        new Date().getTime() / 1000;
                    const itemName = this.list1[i].name;
                    console.log(outTime);
                    if (outTime < 0) {
                        clearInterval(this.timer);
                        this.timer = null;
                        let index = this.list1.findIndex((ele) => {
                            return ele.id === this.list1[i].id;
                        });
                        if (index >= 0) {
                            this.list1.splice(index, 1);
                        }
                        this.$notify({
                            title: '线索超时',
                            dangerouslyUseHTMLString: true,
                            message:
                                '线索（' +
                                itemName +
                                "）超时已流入<font style='color:#4086EC'>【公共池】</font>",
                        });
                        this.refreshClueData();
                    }
                }
            }, 1000);
        },
        //跟进按钮
        followup(item) {
            let data = {
                id: item.id,
                tableName: 'realTimeNewClues',
            };
            this.$emit('followPage', data);
        },
        changeActive(item) {
            this.activeIndex = item.id;
        },
        removeActive() {
            this.activeIndex = '';
        },
        // 页面高度或者宽度发生变化
        // 无限加载
        loadMore() {
            this.loadMoreData = true;
            this.status.pageSize += 20; //页数+1
            this.getNewClue(); //调用接口，此时页数+1，查询下一页数据
        },
    },
};
</script>
<style lang="less" scoped>
.none {
    background: #fff;
    width: 100%;
    height: calc(100% - 42px);
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        width: 100%;
        text-align: center;
    }
}
main {
    background: #fff;
    // width: 100%;
    height: calc(100% - 35px);
    margin: 10px;
    padding: 20px;
    border-radius: 4px;
    .title {
        color: #555;
        border-bottom: 1px solid #dcdfe6;
        padding-bottom: 10px;
        margin-bottom: 5px;
        .batchCallBtn {
            transform: translateX(20px);
        }
    }
    ::v-deep .el-col-6 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .item {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        cursor: pointer;
        .top {
            border-bottom: 1px solid #dcdfe6;
            padding: 14px;
            .one {
                font-size: 14px;
                display: flex;
                justify-content: space-between;

                i {
                    position: relative;
                }
                i::before {
                    content: '';
                    background: #4086ec;
                    position: absolute;
                    left: -7px;
                    top: 4px;
                    width: 3px;
                    height: 12px;
                }
                .mobile-area {
                    display: flex;
                    align-items: center;
                }
            }
            .two {
                margin-top: 10px;
                font-size: 12px;
                color: #7f7f7f;
                display: flex;
                justify-content: space-between;
                span:first-child {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // width: 30%;
                    width: 85px;
                }
            }
            .three {
                min-height: 20px;
                text-align: center;
                margin-top: 10px;
                ::v-deep .el-col-25 {
                    width: 20%;
                }
                span {
                    font-size: 14px;
                    padding: 3px 10px;
                    border-radius: 30px;
                }
                .car {
                    color: #4086ec;
                    border: 1px solid #4086ec;
                }
                .house {
                    color: #ed5939;
                    border: 1px solid #ed5939;
                }
                .gongjijin {
                    color: #e48900;
                    border: 1px solid #e48900;
                }
                .shebao {
                    color: #88c91d;
                    border: 1px solid #88c91d;
                }
                .baodan {
                    color: #557ebc;
                    border: 1px solid #557ebc;
                }
            }
        }
        .bottom {
            display: flex;
            justify-content: space-between;
            padding: 0 14px;
            font-size: 12px;
            line-height: 40px;
            .left {
                position: relative;
                // margin-left: 10px;
            }
            // .left::before {
            //   content: "";
            //   width: 6px;
            //   height: 6px;
            //   background: #ff8040;
            //   border-radius: 50%;
            //   position: absolute;
            //   top: 17px;
            //   left: -10px;
            // }
            // .leftOld::before {
            //   background: #ff8040;
            // }
            // .leftNew::before {
            //   background: #4086ec;
            // }
            .leftNew {
                // 1.1.0修改样式
                margin-top: 8px;
                height: 25px;
                line-height: 26px;
                background-color: #ccc;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 10px;
                background-color: #ff8040;
                color: #fff;
                // 1.1.0修改样式
            }
            i {
                margin-left: 5px;
                font-size: 20px;
                vertical-align: middle;
                color: #ff8190;
            }
            .felx-sb {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
::v-deep .el-col-6 {
    width: 20%;
    font-size: 14px;
}
@media (max-width: 1440px) {
    ::v-deep .el-col-6 {
        width: 25%;
        font-size: 13px !important;
    }
}

.active {
    // box-shadow: 1px 1px 8px 2px #ccc;
    box-shadow: 0px 0px 10px #7f7f7f;
    transition: all 0.3s;
    transform: translate(0, -10px);
    // top: -20px;
    position: relative;
}
.remove-active {
    transition: all 0.3s;
    transform: translate(0, 0);
}
.clue {
    font-size: 18px;
    color: #aaaaaa;
}
</style>
