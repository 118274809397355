<template>
    <div ref="page" class="box">
        <div class="searchOpen">
            <search style="margin-top:10px" :label-arr="labelArr" @clickData="clickData" />
        </div>
        <el-card class="main" :style="{'margin-top': '10px'}">
            <div>
                <el-button type="primary" @click="batchCall">批量呼叫</el-button>
            </div>
            <el-table
                v-loading="loading"
                :data="tableData"
                border
                style="width: 100%;margin-top:10px"
                :height="tableHeight(332)"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" fixed />
                <el-table-column label="序号" type="index" width="55" />
                <el-table-column label="客户姓名" prop="name" width="150">
                    <template slot-scope="scope">
                        <div class="flex">
                            <div style>{{ scope.row.name }}</div>

                            <div v-show="scope.row.newClient == 1" class="new">新</div>
                            <i v-if="scope.row.important == 1" class="iconfont icon-aixin aixin" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="客户资质" width="190" prop>
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.hasHouse == 1"
                            class="icon house"
                            style="margin-right: 10px"
                        >房</span>
                        <span
                            v-if="scope.row.hasVehicle == 1"
                            class="icon car"
                            style="margin-right: 10px"
                        >车</span>
                        <span
                            v-if="scope.row.hasShebao == 1"
                            style="margin-right: 10px"
                            class="icon shebao"
                        >社</span>
                        <span
                            v-if="scope.row.hasGongjijin == 1"
                            class="icon gongjijin"
                            style="margin-right: 10px"
                        >金</span>
                        <span
                            v-if="scope.row.hasBaodan == 1"
                            style="margin-right: 10px"
                            class="icon baodan"
                        >保</span>
                    </template>
                </el-table-column>
                <el-table-column label="资金需求" prop>
                    <template slot-scope="scope">{{ scope.row.fundNeeds }}万</template>
                </el-table-column>
                <!-- <el-table-column label="线索状态" prop="status"></el-table-column> -->
                <el-table-column label="渠道来源" prop="source" />
                <el-table-column label="剩余跟进时间">
                    <template slot-scope="scope">
                        <time style="color:#DE3C3C">{{ scope.row.restTime }}</time>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="toFollow(scope.row)">跟进</el-button>
                        <el-button
                            v-if="showTransBtn"
                            type="warning"
                            size="mini"
                            @click="transfer(scope.row)"
                        >转移</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="block" style="margin-top: 10px; text-align: right">
                <el-pagination
                    :current-page="page"
                    :page-size="pageLimit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="len"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>

            <assign-dialog
                :title="title"
                :show-dialog.sync="showDialog"
                @submitPartment="submitPartment"
            />
        </el-card>
        <turn-call-dialog
            :show-turn-call-dialog.sync="showTurnCallDialog"
            @useNewList="useNewList"
            @useOldList="useOldList"
        />
    </div>
</template>
<script>
import search from '@/components/templates/search-open';
import { newClue, transfer_clue } from '@/api/my_clue.js';
import elementResizeDetectorMaker from 'element-resize-detector';
import commonAlert from '@/mixins/commonAlert.js';
import clueBulkCall from '@/mixins/clueBulkCall.js';
import resize from '@/mixins/resize';
export default {
    components: {
        search,
        assignDialog: () => import('../../client/components/assignDialog'),
    },
    inject: ['refreshClueData'],
    mixins: [clueBulkCall, commonAlert, resize],
    data() {
        return {
            pageType: 0, //他是一个静态属性，用于帮助混淆识别页面，如果你不知道他的真实作用，请不要更改他
            client_Ids: [], //表格选中的CheckBox存放客户id值
            showTurnCallDialog: false,
            isShow: 0,
            activeName: 0,
            valuetime: '', //时间段搜索
            timeTabs: 0,
            tableData: [],
            labelArr: ['客户资质', '资金需求', '新老客'],
            status: {
                qualityType: '', //资质类型
                startTime: '', //开始时间
                endTime: '', //结束时间
                newClient: '', //新老客户
                fundNeedsScopeType: '', //资金需求
                pageNo: 1, //页码数
                pageSize: 10, //数据量
            },
            page: 1,
            len: 0,
            pageLimit: 10,
            loading: true,
            // tableHeight: window.innerHeight - 310,
            title: '选择要转移的对象',
            showDialog: false,
            transferId: '',
            showTransBtn: sessionStorage.getItem('showTransBtn'),
            searchOpenHeight: '',
            onRiseHeight: window.innerHeight - 310,
        };
    },
    watch: {},
    created() {
        window.addEventListener('resize', this.watchSize);
        // window.addEventListener("resize", this.getHeight);
        //刷新后还是在这个标签
        let c = sessionStorage.getItem('path2');
        if (c) {
            this.isShow = c;
            this.activeName = c;
        }
    },
    mounted() {
        this.watchSize();
        this.getTableData();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.watchSize);
        // window.removeEventListener("resize", this.getHeight);
    },
    methods: {
        // 批量呼叫
        batchCall() {
            if (this.callServices.isDisabled()) {
                this.alertCallServicesNotOpen();
                return;
            }

            this.getMyClueCallList();
        },
        handleSelectionChange(val) {
            this.client_Ids = val;
        },
        watchSize() {
            const _this = this;
            const erd = elementResizeDetectorMaker({
                strategy: 'scroll',
            });
            erd.listenTo(
                document.getElementsByClassName('searchOpen'),
                (element) => {
                    let height = element.offsetHeight;
                    _this.$nextTick(() => {
                        _this.searchOpenHeight = height - 50;
                        //监听到事件后执行的业务逻辑
                        // _this.searchOpenHeight = height - 50;
                        // console.log(_this.tableHeight - height -50 + 'px');
                        // _this.tableHeight = _this.tableHeight - height - 50 + "px";
                        // console.log(_this.tableHeight);
                    });
                }
            );
        },
        showTips(obj, row) {
            /*obj为鼠标移入时的事件对象*/

            /*currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
            let TemporaryTag = document.createElement('span');
            TemporaryTag.innerText = row.remark;
            TemporaryTag.className = 'getTextWidth';
            document.querySelector('body').appendChild(TemporaryTag);
            let currentWidth =
                document.querySelector('.getTextWidth').offsetWidth;
            document.querySelector('.getTextWidth').remove();

            /*cellWidth为表格容器的宽度*/
            const cellWidth = obj.target.offsetWidth;

            /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
            currentWidth <= 2 * cellWidth
                ? (row.showTooltip = false)
                : (row.showTooltip = true);
        },
        getHeight() {
            // this.tableHeight = this.tableData.length > 0 ? window.innerHeight - 250 : "";
            this.tableHeight = window.innerHeight - 310;
        },
        getTableData() {
            newClue(this.status).then((res) => {
                console.log(res);
                if (res.code == 0) {
                    this.loading = true;
                    let data = res.data.list;
                    // 查找字典code为clue-status
                    let clueStatus = this.common.allValueNeed('clue-status');
                    data.forEach((item, index) => {
                        // 查找线索状态
                        if (Array.isArray(clueStatus)) {
                            clueStatus.forEach((i) => {
                                if (item.status == i.number) {
                                    item.status = i.cnVal;
                                }
                            });
                        }
                        // 动态时间
                        item.restTime = this.common.InitTime(item.expiredTime);
                        let restOutTime = null;
                        restOutTime = setInterval(() => {
                            item.restTime = this.common.InitTime(
                                item.expiredTime
                            );
                            let outTime =
                                parseInt(item.expiredTime) -
                                new Date().getTime() / 1000;
                            if (outTime < 0) {
                                data.splice(index, 1);
                                clearInterval(restOutTime);
                            }
                        }, 1000);
                    });
                    this.tableData = data;
                    this.len = res.data.total;
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //分页选择
        handleSizeChange(val) {
            console.log(val);
            this.size = val;
            this.status.pageSize = val;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.status.pageNo = this.page;
            this.getTableData();
        },
        // 搜索触发事件
        findData() {
            this.getTableData();
        },
        // search组件传值事件
        clickData(val) {
            console.log(val.newClient);
            this.status.qualityType = val.qualityType;
            this.status.fundNeedsScopeType = val.fundNeedsScopeType;
            this.status.newClient =
                val.newClient == 1 ? 1 : val.newClient == 2 ? 0 : '';
            this.getTableData();
        },
        // 转移
        transfer(row) {
            console.log(row.id, 'row.id');
            this.transferId = '';
            this.transferId = row.id;
            this.showDialog = true;
        },
        submitPartment(val) {
            console.log(this.transferId, 'this.transferId');
            console.log(val, 'valthis.transferId');
            transfer_clue({ clueId: this.transferId, staffId: val }).then(
                (res) => {
                    console.log(res);
                    if (res === undefined)
                        return this.$message.error('权限不足');
                    if (res.code == 0) {
                        this.$message({
                            message: '已将线索转移',
                            type: 'success',
                        });
                        this.showDialog = false;
                        this.getTableData();
                        this.refreshClueData();
                    } else {
                        this.$message.error('参数不可为空');
                    }
                }
            );
        },
        // 跟进页面转跳
        toFollow(row) {
            let data = {
                id: row.id,
                tableName: 'newClues',
                qualityType: this.status.qualityType,
                fundNeedsScopeType: this.status.fundNeedsScopeType,
                status: this.status.status,
                statusTag: '',
                newClient: this.status.newClient,
            };
            this.$emit('followPage', data);
        },
    },
};
</script>
<style lang="less" scoped>
@import '@/css/table.less';
.flex {
    display: flex;
}
/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #333;
}
.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search {
    .top-search {
        position: relative;
        /deep/ .el-input__clear {
            margin-right: 23px;
        }
        .icon-area {
            position: absolute;
            right: 5px;
            top: 8px;
            border-left: 1px solid #ccc;
            padding-left: 5px;
            cursor: pointer;
        }
    }
    .right-time {
        div:not(:last-child) {
            margin-right: 10px;
        }
        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #4086ec;
            background-color: #ecf3fd;
            box-shadow: 0 0px 0px #ccc;
            border-color: #dcdfe6;
        }
        /deep/ .el-radio-button__inner {
            color: #aaa;
        }
    }
}
.table-area {
    margin-top: 10px;
}
.box {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    overflow-y: scroll;

    .main {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.new {
    background-color: #ff8040;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 2%;
}
/deep/ .el-input__clear {
    margin-right: 23px;
}
.new {
    background-color: #ff8040;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 2%;
}
/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}
.aixin {
    margin-left: 5px;
    font-size: 20px;
    vertical-align: middle;
    color: #ff8190;
}
</style>
